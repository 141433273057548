import { TermsAndConditions } from 'components/termsAndConditions/TermsAndConditions'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { ThemeContext, THEME_OPTIONS } from 'configs/theme.config'
import { SvgIcon } from 'components/icon/SvgIcon'

import styles from './PageWrapper.module.scss'

export class PageWrapper extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string]),
  }

  static contextType = ThemeContext

  get isChristmasTheme() {
    return this.context === THEME_OPTIONS.CHRISTMAS
  }

  get logoImg() {
    if (this.isChristmasTheme) {
      return 'logo-christmas-white'
    } else {
      return 'logo'
    }
  }

  render() {
    const { children } = this.props

    return (
      <div className={styles.pageWrapper}>
        <div className={styles.logoWrapper}>
          <SvgIcon name={this.logoImg} className={styles.logo} />
        </div>
        {children}

        <TermsAndConditions />
      </div>
    )
  }
}
