import React from 'react'

import styles from './TermsAndConditions.module.scss'

export const TermsAndConditions = () => (
  <div className={styles.termsAndConditions}>
    <a href="https://ess.com/terms-conditions/" target="_blank" rel="noopener noreferrer">
      Terms & Conditions
    </a>
  </div>
)
