import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { snakeCase } from 'lodash'
import { SvgIcon } from 'components/icon/index'
import { Label } from 'components/label/Label'

import './Input.css'

export const Input = ({
  type = 'text',
  value,
  onChange,
  placeholder = '',
  min,
  max,
  label,
  disabled = false,
  error = '',
  className = '',
  size = 'regular',
  icon,
  hasClearIcon = false,
  onClear,
  validationPattern = null,
  setInputRef,
  isRequired,
  autoComplete,
}) => {
  const containerClassName = classNames(
    'input-cmp',
    {
      withIcon: !!icon,
      withClearIcon: hasClearIcon,
      disabled,
      error: !!error,
      small: size === 'small',
    },
    className,
  )

  const inputId = label ? `${snakeCase(label)}-input` : undefined

  return (
    <div className={containerClassName}>
      {label && (
        <label className="input-header" htmlFor={inputId}>
          <Label isRequired={isRequired}>{label}</Label>
        </label>
      )}
      <div className="input-wrapper">
        <input
          id={inputId}
          type={type}
          value={value}
          placeholder={placeholder}
          min={min}
          max={max}
          onChange={e =>
            validationPattern ? validationPattern(e.target.value) && onChange(e.target.value) : onChange(e.target.value)
          }
          disabled={disabled}
          ref={setInputRef}
          autoComplete={autoComplete}
        />
        {icon && <SvgIcon name={icon} className="icon" height="14px" width="14px" />}
        {hasClearIcon &&
          value &&
          !disabled && (
            <span className={classNames('icon-wrapper', 'clear-icon')} onClick={onClear}>
              <SvgIcon name="cancel" height="10px" width="10px" />
            </span>
          )}
      </div>
      {error && <div className="error-block">{error}</div>}
    </div>
  )
}

Input.propTypes = {
  type: PropTypes.oneOf(['text', 'number', 'password']),
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['regular', 'small']),
  icon: PropTypes.string,
  autoComplete: PropTypes.string,
  hasClearIcon: PropTypes.bool,
  isRequired: PropTypes.bool,
  onClear: PropTypes.func,
  validationPattern: PropTypes.func,
  setInputRef: PropTypes.func,
}
