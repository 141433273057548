import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { SvgIcon } from 'components/icon/SvgIcon'

import styles from './FormMessage.module.scss'

export const FORM_MESSAGE_TYPES = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  SUCCESS: 'SUCCESS',
}

export const USER_STATUSES = {
  REACTIVATE_REAPPLY: 'REACTIVATE_REAPPLY',
  DA_CONTACT: 'DA_CONTACT',
  ESS_CONTACT: 'ESS_CONTACT',
}

export const getMessageByUserStatus = status => {
  switch (status) {
    case USER_STATUSES.REACTIVATE_REAPPLY:
      return (
        <div style={{ maxWidth: '350px' }}>
          <p>
            Your account has been inactivated. Please click the Reactivate/Reapply button below if you need to reapply
            with ESS or reactivate your account. Otherwise please visit{' '}
            <a href="https://ess.com/contact/">https://ess.com/contact/</a> to connect with your support team.
          </p>
        </div>
      )
    case USER_STATUSES.DA_CONTACT:
      return 'Your account has been inactivated. Please contact your district administration for support.'
    case USER_STATUSES.ESS_CONTACT:
      return (
        <div style={{ maxWidth: '350px' }}>
          <p>
            Your account has been inactivated. Please visit{' '}
            <a href="https://ess.com/contact/">https://ess.com/contact/</a> to connect with your support team.
          </p>
        </div>
      )
    default:
      return ''
  }
}

const getMessageIconNameByType = type => {
  switch (type) {
    case FORM_MESSAGE_TYPES.ERROR:
      return 'error'
    case FORM_MESSAGE_TYPES.WARNING:
      return 'warning'
    default:
      return 'completed'
  }
}

const getMessageClassNameByType = type => {
  switch (type) {
    case FORM_MESSAGE_TYPES.ERROR:
      return styles.messageError
    case FORM_MESSAGE_TYPES.WARNING:
      return 'messageWarning'
    default:
      return 'messageCompleted'
  }
}

export const getErrorMessageByStatus = status => {
  switch (status) {
    case 401:
      return 'Invalid username or password.'
    case 403:
      return (
        <div style={{ maxWidth: '350px' }}>
          <p>
            Your account has been inactivated. If you are a district employee, please contact your district
            administration. If you are an applicant or substitute, please visit{' '}
            <a href="https://ess.com/contact/">https://ess.com/contact/</a> to connect with your support team.
          </p>
        </div>
      )
    default:
      return (
        <div style={{ maxWidth: '350px' }}>
          <p>
            Willsub+ is currently down for maintenance. We should be back up soon. Please wait a few minutes and try
            logging in again. We are sorry for any inconvenience, thank you for your patience!
          </p>
        </div>
      )
  }
}

export const FormMessage = ({ type = FORM_MESSAGE_TYPES.INFO, text }) => {
  const iconName = getMessageIconNameByType(type)
  const messageClassName = getMessageClassNameByType(type)

  return (
    <div className={classNames(styles.formMessage, messageClassName)}>
      <SvgIcon name={iconName} />
      <span>{text}</span>
    </div>
  )
}

FormMessage.propTypes = {
  type: PropTypes.oneOf(Object.keys(FORM_MESSAGE_TYPES)),
  text: PropTypes.string.isRequired,
}
