import React, { Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { getActiveTheme, ThemeContext } from 'configs/theme.config'
import { VideoBackground } from 'components/videoBackground/VideoBackground'
import { SvgSprite } from 'components/icon/SvgSprite'
import { LoginContainer } from 'containers/login/LoginContainer'
import { ForgotPasswordContainer } from 'containers/forgotPassword/ForgotPasswordContainer'
import { ResetPasswordContainer } from 'containers/resetPassword/ResetPasswordContainer'
import { PageWrapper } from 'components/pageWrapper/PageWrapper'
// import { Help } from 'components/help/Help'

import './App.css'

export const App = () => {
  const theme = getActiveTheme()

  return (
    <ThemeContext.Provider value={theme}>
      <Fragment>
        <VideoBackground />
        {/* ORION-15270. Temporary disabled. Need to do help block for specific districts. */}
        {/*<Help />*/}

        <Router>
          <PageWrapper>
            <Switch>
              <Route exact path="/password" component={ForgotPasswordContainer} />
              <Route exact path="/reset-password" component={ResetPasswordContainer} />
              <Route path="/" component={LoginContainer} />
            </Switch>
          </PageWrapper>
        </Router>

        <SvgSprite />
      </Fragment>
    </ThemeContext.Provider>
  )
}
