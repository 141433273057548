import { get, merge } from 'lodash'
import deepFreeze from 'deep-freeze'
import { exposeToWindow } from 'utils/exposeToWindow'

/**
 * Default config values which can be change on deployed environments.
 */
const defaultConfig = {
  API_BASE_URL: 'qa-1.qa.willsubplus.com',
}

/**
 * Calculated effectiveConfig based on the env
 */
const effectiveConfig = deepFreeze(merge(defaultConfig, get(window, 'ORION.env', {})))

exposeToWindow('config', effectiveConfig)

export const config = effectiveConfig
