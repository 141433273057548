export const MIN_PASSWORD_LENGTH = 8
const LOWER_CASE_MIN_COUNT = 1
const UPPER_CASE_MIN_COUNT = 1

export const VALIDATION_ERRORS_ENUM = {
  SHORT_PASSWORD: `Invalid password: minimum length ${MIN_PASSWORD_LENGTH}.`,
  PASSWORDS_NOT_MATCH: "Passwords don't match.",
  MUST_CONTAIN_LOWER_CASE: `Invalid password: must contain at least ${LOWER_CASE_MIN_COUNT} lower case characters.`,
  MUST_CONTAIN_UPPER_CASE: `Invalid password: must contain at least ${UPPER_CASE_MIN_COUNT} upper case characters.`,
  CANNOT_CONTAIN_SPACES: `Invalid password: cannot contain spaces.`,
  CANNOT_BE_EMPTY: `Field cannot be empty.`,
}

export const passwordValidators = [
  {
    name: 'Check spaces',
    isValid: value => !value.match(/\s/gm),
    errorMessage: VALIDATION_ERRORS_ENUM.CANNOT_CONTAIN_SPACES,
  },
  {
    name: 'Check minimum password length',
    isValid: value => value.length >= MIN_PASSWORD_LENGTH,
    errorMessage: VALIDATION_ERRORS_ENUM.SHORT_PASSWORD,
  },
  {
    name: 'Check upper case letter is present',
    isValid: value => {
      const upperCaseLetters = value.match(/[A-Z]/gm)

      return upperCaseLetters && upperCaseLetters.length >= UPPER_CASE_MIN_COUNT
    },
    errorMessage: VALIDATION_ERRORS_ENUM.MUST_CONTAIN_UPPER_CASE,
  },
  {
    name: 'Check lower case letter is present',
    isValid: value => {
      const lowerCaseLetters = value.match(/[a-z]/gm)

      return lowerCaseLetters && lowerCaseLetters.length >= LOWER_CASE_MIN_COUNT
    },
    errorMessage: VALIDATION_ERRORS_ENUM.MUST_CONTAIN_LOWER_CASE,
  },
]

export const validatePassword = value => {
  const result = passwordValidators.reduce((result, validator) => {
    const errorMessage = !validator.isValid(value) && validator.errorMessage

    errorMessage && result.push(errorMessage)

    return result
  }, [])

  return result.length ? result[0] : ''
}

export const validateRepeatPasswordField = ({ passwordField, value }) =>
  passwordField === value ? '' : VALIDATION_ERRORS_ENUM.PASSWORDS_NOT_MATCH

export const validateCurrentPasswordField = value => (!value ? VALIDATION_ERRORS_ENUM.CANNOT_BE_EMPTY : '')
