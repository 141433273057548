import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Input, Button } from 'components/ui-kit'
import { FormRow } from 'components/formRow/FormRow'
import { FormMessage } from 'components/formMessage/FormMessage'

import styles from './LoginView.module.scss'

export const LoginView = ({
  email,
  password,
  validationError,
  handleEmailChange,
  handlePasswordChange,
  handleLogin,
  handleReactivate,
  isReady,
  formMessage,
  showReactivateBtn,
  reactivateBtnLoading,
  setInputEmailRef,
}) => (
  <Fragment>
    <FormRow>
      <Input
        icon="user"
        className="transparent"
        placeholder="Email"
        value={email}
        error={validationError.email}
        onChange={handleEmailChange}
        setInputRef={setInputEmailRef}
        autoComplete="email"
      />
    </FormRow>
    <FormRow>
      <Input
        type="password"
        icon="password"
        className="transparent"
        placeholder="Password"
        value={password}
        error={validationError.password}
        autoComplete="current-password"
        onChange={handlePasswordChange}
      />
    </FormRow>
    <FormRow className={styles.rememberMe}>
      <div>{/*<Checkbox textLabel="Remember me" onChange={() => {}} />*/}</div>
      <Link className={styles.forgotPasswordLink} to="/password">
        Forgot Password?
      </Link>
    </FormRow>
    <FormRow buttonContainer>
      <Button type="primary" size="big" pending={!isReady} onClick={handleLogin}>
        Log in
      </Button>
    </FormRow>
    {formMessage && <FormMessage text={formMessage.text} type={formMessage.type} />}

    {showReactivateBtn && (
      <FormRow buttonContainer>
        <br />
        <br />
        <Button type="primary" size="big" pending={reactivateBtnLoading} onClick={handleReactivate}>
          Reactivate / Reapply
        </Button>
      </FormRow>
    )}
  </Fragment>
)

LoginView.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  validationError: PropTypes.object.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  handleReactivate: PropTypes.func.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  setInputEmailRef: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  isReady: PropTypes.bool.isRequired,
  showReactivateBtn: PropTypes.bool.isRequired,
  reactivateBtnLoading: PropTypes.bool.isRequired,
  formMessage: PropTypes.object,
}
