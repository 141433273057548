/* eslint-disable jsx-a11y/label-has-for */
import React from 'react'
import { compose, withHandlers, withState } from 'recompose'
import { isString } from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withComponentDidMount } from 'hoc/withComponentDidMount'
import { withComponentWillReceiveProps } from 'hoc/withComponentWillReceiveProps'
import { Text } from 'components/ui-kit'
import './Checkbox.css'

export const CheckboxCmp = ({
  isInputChecked = false,
  isDisabled = false,
  isMixed = false,
  isValid = true,
  className,
  textLabel,
  errorMessage,
  handleInputClick,
}) => (
  <div
    className={classNames('checkbox', className, {
      mixed: isMixed,
      invalidated: !isValid && !isDisabled,
      checked: isInputChecked,
      disabled: isDisabled,
    })}>
    <label onClick={handleInputClick}>
      <input type="checkbox" value={isInputChecked} checked={isInputChecked} disabled={isDisabled} />
      {isString(textLabel) ? <Text type="paragraph">{textLabel}</Text> : textLabel}
    </label>
    {!isValid &&
      !isDisabled &&
      errorMessage && (
        <Text type="hint" className="error">
          {errorMessage}
        </Text>
      )}
  </div>
)

CheckboxCmp.propTypes = {
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMixed: PropTypes.bool,
  isValid: PropTypes.bool,
  isInputChecked: PropTypes.bool,
  onChange: PropTypes.func,
  handleInputClick: PropTypes.func,
  textLabel: PropTypes.node,
  errorMessage: PropTypes.string,
}

const handleComponentDidMount = ({ isChecked, setIsInputChecked }) => setIsInputChecked(isChecked)

const handleComponentWillReceiveProps = (prevProps, nextProps) => {
  if (nextProps.isChecked !== prevProps.isChecked) {
    nextProps.setIsInputChecked(nextProps.isChecked)
  }
}

const handleInputClick = ({ onChange, setIsInputChecked, isInputChecked, isMixed }) => () =>
  setTimeout(() => {
    const isChecked = isMixed ? false : !isInputChecked

    onChange(isChecked)
    setIsInputChecked(isChecked)
  })

export const Checkbox = compose(
  withState('isInputChecked', 'setIsInputChecked', false),
  withHandlers({
    handleInputClick,
  }),
  withComponentDidMount(handleComponentDidMount),
  withComponentWillReceiveProps(handleComponentWillReceiveProps),
)(CheckboxCmp)
