import React, { Fragment, isValidElement } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { includes } from 'lodash'

import { Spinner } from 'components/ui-kit'

import styles from './Button.module.scss'

export const BUTTON_TYPES = [
  'primary',
  'primary-green',
  'primary-inverted',
  'primary-inverted-black',
  'secondary',
  'secondary-inverted',
  'tertiary',
  'load-more',
  'context-menu',
  'context-menu-inverted',
  'toggle',
]

export const BUTTON_SIZES = ['big', 'regular', 'small']

export const BUTTON_LETTER_SPACING = ['narrow', 'regular', 'wide']

const getSpinnerColor = buttonType => {
  const blueSpinnerTypes = ['primary-inverted', 'secondary', 'load-more']
  const whiteSpinnerTypes = ['primary', 'primary-green', 'secondary-inverted']
  const blackSpinnerTypes = ['primary-inverted-black']
  const greySpinnerTypes = ['tertiary']

  if (includes(blueSpinnerTypes, buttonType)) {
    return 'blue'
  }

  if (includes(whiteSpinnerTypes, buttonType)) {
    return 'white'
  }

  if (includes(blackSpinnerTypes, buttonType)) {
    return 'black'
  }

  if (includes(greySpinnerTypes, buttonType)) {
    return 'gray'
  }

  return 'blue'
}

export const Button = ({
  actionType = 'button',
  size = 'regular',
  type = 'primary',
  letterSpacing,
  toggled = false,
  pending = false,
  disabled = false,
  stretch = false,
  // todo: Remove iconLeft, iconRight. These params are deprecated. Pass icon as part of btn children instead.
  iconLeft = null,
  iconRight = null,
  onClick,
  children,
  className,
  ...props
}) => {
  const classList = classNames(
    styles.baseButton,
    {
      [styles.pending]: pending,
      [styles.disabled]: disabled,
      [styles.toggleButtonToggled]: toggled,

      [styles.buttonBig]: size === 'big',
      [styles.buttonRegular]: size === 'regular',
      [styles.buttonSmall]: size === 'small',
      [styles.buttonStretch]: stretch,

      [styles.primaryButton]: type === 'primary',
      [styles.primaryGreenButton]: type === 'primary-green',
      [styles.primaryInvertedButton]: type === 'primary-inverted',
      [styles.primaryInvertedBlackButton]: type === 'primary-inverted-black',
      [styles.secondaryButton]: type === 'secondary',
      [styles.secondaryInvertedButton]: type === 'secondary-inverted',
      [styles.tertiaryButton]: type === 'tertiary',
      [styles.loadMoreButton]: type === 'load-more',
      [styles.contextMenuButton]: type === 'context-menu',
      [styles.contextMenuButtonInverted]: type === 'context-menu-inverted',
      [styles.toggleButton]: type === 'toggle',

      [styles.narrow]: letterSpacing === 'narrow',
      [styles.wide]: letterSpacing === 'wide',
    },
    className,
  )
  const spinnerColor = getSpinnerColor(type)
  const isContextMenu = type === 'context-menu' || type === 'context-menu-inverted'

  return (
    <button className={classList} disabled={disabled} onClick={onClick} type={actionType} {...props}>
      {isContextMenu ? (
        <div className={styles.baseButtonDotsContainer}>
          <div className={styles.baseButtonDot} />
          <div className={styles.baseButtonDot} />
          <div className={styles.baseButtonDot} />
        </div>
      ) : (
        <Fragment>
          {pending && <Spinner className={styles.spinner} color={spinnerColor} width={19} height={19} />}

          {isValidElement(iconLeft) && iconLeft}

          {children}

          {isValidElement(iconRight) && iconRight}
        </Fragment>
      )}
    </button>
  )
}

Button.propTypes = {
  actionType: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  stretch: PropTypes.bool,
  toggled: PropTypes.bool,
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(BUTTON_SIZES),
  type: PropTypes.oneOf(BUTTON_TYPES),
  letterSpacing: PropTypes.oneOf(BUTTON_LETTER_SPACING),
}
