import { compose, withState, withHandlers } from 'recompose'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { get } from 'lodash'

import { validatePassword, validateRepeatPasswordField } from 'utils/helpers/password'
import { isEnterPressed } from 'utils/helpers/keyboardHelpers'
import { API } from 'utils/api/api'
import { withComponentDidMount } from 'hoc/withComponentDidMount'
import { withComponentWillUnmount } from 'hoc/withComponentWillUnmount'
import { ResetPasswordView } from 'containers/resetPassword/ResetPasswordView'
import { FORM_MESSAGE_TYPES } from 'components/formMessage/FormMessage'

const handlePasswordChange = ({
  setPassword,
  setPasswordError,
  setIsReady,
  confirmPassword,
  setConfirmPasswordError,
}) => password => {
  let confirmPasswordError
  const passwordError = validatePassword(password)

  setPassword(password)
  setPasswordError(passwordError)

  confirmPasswordError = validateRepeatPasswordField({ passwordField: password, value: confirmPassword })
  setConfirmPasswordError(confirmPasswordError)
  setIsReady(!(passwordError || confirmPasswordError))
}
const handleConfirmPasswordChange = ({
  setConfirmPassword,
  setConfirmPasswordError,
  setIsReady,
  password,
}) => confirmPassword => {
  const passwordError = validatePassword(password)
  const confirmPasswordError = validateRepeatPasswordField({ passwordField: password, value: confirmPassword })

  setConfirmPasswordError(confirmPasswordError)
  setConfirmPassword(confirmPassword)
  setIsReady(!(passwordError || confirmPasswordError))
}

const handleEnterKeyDown = ({ isReady, handleSubmit }) => e => isEnterPressed(e) && isReady && handleSubmit()

let enterKeyPressEventListener
const handleComponentDidMount = ({ setToken, handleEnterKeyDown }) => {
  const params = queryString.parse(window.location.search)

  setToken(get(params, 'token'))

  enterKeyPressEventListener = handleEnterKeyDown
  document.addEventListener('keypress', enterKeyPressEventListener)
}

const handleComponentWillUnmount = () => document.removeEventListener('keypress', enterKeyPressEventListener)

const handleSubmit = ({ token, password, setPending, setFormMessage, history }) => () => {
  setPending(true)

  API.patch(`/auth/password/update?${queryString.stringify({ token, password })}`)
    .then(() => {
      history.push({
        pathname: '/',
        state: {
          formMessage: {
            type: FORM_MESSAGE_TYPES.SUCCESS,
            text: 'Password has been successfully changed. Use it to log in to the application.',
          },
        },
      })
    })
    .catch(err => {
      const errorToShow = get(err, 'response.data.message')

      errorToShow &&
        setFormMessage({
          type: FORM_MESSAGE_TYPES.ERROR,
          text: errorToShow,
        })
    })
}

export const ResetPasswordContainer = compose(
  withRouter,
  withState('password', 'setPassword', ''),
  withState('passwordError', 'setPasswordError', ''),
  withState('confirmPassword', 'setConfirmPassword', ''),
  withState('confirmPasswordError', 'setConfirmPasswordError', ''),
  withState('token', 'setToken', null),
  withState('formMessage', 'setFormMessage', null),
  withState('isReady', 'setIsReady', false),
  withState('pending', 'setPending', false),
  withHandlers({
    handlePasswordChange,
    handleConfirmPasswordChange,
    handleSubmit,
  }),
  // Do not move this inside prev withHandlers call. as we run handleSubmit inside handleEnterKeyDown
  withHandlers({
    handleEnterKeyDown,
  }),
  withComponentDidMount(handleComponentDidMount),
  withComponentWillUnmount(handleComponentWillUnmount),
)(ResetPasswordView)
