import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './FormRow.module.scss'

export const FormRow = ({ children, buttonContainer = false, className }) => (
  <div
    className={classNames(styles.formRow, className, {
      [styles.buttonContainer]: buttonContainer,
    })}>
    {children}
  </div>
)

FormRow.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonContainer: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string]),
}
