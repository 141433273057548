export const FLOW_TYPES = {
  REFRESH_ACCESS_TOKEN: 'REFRESH_ACCESS_TOKEN',
}

export const TOKEN_MIN_VALID_TIME = 10

export const TOKENS_TYPES = {
  REFRESH_TOKEN: 'refreshToken',
  ACCESS_TOKEN: 'token',
}

export const INITIALISATION_FLOWS = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  RESET_PASSWORD: 'RESET_PASSWORD',
  SET_TOKENS: 'SET_TOKENS',
  CHECK_IS_LOGGED_IN: 'CHECK_IS_LOGGED_IN',
}
