import { compose, withState, withHandlers } from 'recompose'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

import { AuthManagerInstance } from 'utils/auth/AuthManager'
import { isEmailValid } from 'utils/helpers/email'
import { isEnterPressed } from 'utils/helpers/keyboardHelpers'
import { withComponentDidMount } from 'hoc/withComponentDidMount'
import { withComponentWillUnmount } from 'hoc/withComponentWillUnmount'
import { LoginView } from 'containers/login/LoginView'
import {
  FORM_MESSAGE_TYPES,
  getErrorMessageByStatus,
  getMessageByUserStatus,
  USER_STATUSES,
} from 'components/formMessage/FormMessage'
import { API } from 'utils/api/api'

const requestPublicUserStatus = email => API.post('/public/users/status', { email })
const requestPublicReactivateUser = email => API.post('/public/users/reactivate', { email })

const validateForm = ({ value, valueFocusName, validationError }) => {
  let error = validationError

  if (valueFocusName.includes('email')) {
    if (!isEmailValid(value.email)) {
      error.email = 'Please enter email in format example@site.com'
    } else {
      error.email = ''
    }
  }

  if (valueFocusName.includes('password')) {
    if (value.password.length < 1) {
      error.password = 'Password can not be empty'
    } else {
      error.password = ''
    }
  }

  return error
}

const handleLogin = ({
  email,
  password,
  validationError,
  setValidationError,
  setFormMessage,
  setIsReady,
  setShowReactivateBtn,
}) => () => {
  const error = validateForm({
    value: {
      password,
      email,
    },
    valueFocusName: ['password', 'email'],
    validationError,
  })

  setValidationError(error)

  if (error.password.length > 0 || error.email.length > 0) {
    return
  }

  setIsReady(false)

  AuthManagerInstance.login({ email, password })
    .catch(error => {
      let codeStatus = get(error, 'response.status')

      if (codeStatus === 401) {
        setFormMessage({
          type: FORM_MESSAGE_TYPES.ERROR,
          text: getErrorMessageByStatus(codeStatus),
        })

        return
      }

      // For some reasons we can't get status from response.
      // So in this case to get 403 error we check message name.
      if (error.message === 'Network Error') {
        codeStatus = 403
      }

      requestPublicUserStatus(email)
        .then(response => {
          const status = get(response, 'data.status')

          if (status) {
            setFormMessage({
              type: FORM_MESSAGE_TYPES.ERROR,
              text: getMessageByUserStatus(status),
            })

            if (status === USER_STATUSES.REACTIVATE_REAPPLY) {
              setShowReactivateBtn(true)
            }
          } else {
            setFormMessage({
              type: FORM_MESSAGE_TYPES.ERROR,
              text: getErrorMessageByStatus(codeStatus),
            })
          }
        })
        .catch(() => {
          setFormMessage({
            type: FORM_MESSAGE_TYPES.ERROR,
            text: 'Unable to login, please try again later',
          })
        })
    })
    .finally(() => {
      setIsReady(true)
      setShowReactivateBtn(false)
    })
}

let enterKeyPressEventListener
const handleComponentDidMount = ({ history, setFormMessage, handleLogin }) => {
  const formMessage = get(history, 'location.state.formMessage', false)

  if (formMessage) {
    setFormMessage(formMessage)
    history.push('/')
  }

  enterKeyPressEventListener = e => isEnterPressed(e) && handleLogin()

  document.addEventListener('keypress', enterKeyPressEventListener)
}

const handleComponentWillUnmount = () => document.removeEventListener('keypress', enterKeyPressEventListener)

const handleEmailChange = ({ setEmail, validationError, setValidationError }) => email => {
  setEmail(email)

  const error = validateForm({
    value: {
      email,
    },
    valueFocusName: ['email'],
    validationError,
  })

  setValidationError(error)
}

const handlePasswordChange = ({ setPassword, validationError, setValidationError }) => password => {
  setPassword(password)

  const error = validateForm({
    value: {
      password,
    },
    valueFocusName: ['password'],
    validationError,
  })

  setValidationError(error)
}

const handleReactivate = ({ email, setReactivateBtnLoading }) => () => {
  setReactivateBtnLoading(true)

  requestPublicReactivateUser(email)
    .then(() => window.location.reload())
    .finally(() => setReactivateBtnLoading(false))
}

export const LoginContainer = compose(
  withRouter,
  withState('isReady', 'setIsReady', true),
  withState('password', 'setPassword', ''),
  withState('email', 'setEmail', ''),
  withState('validationError', 'setValidationError', {
    email: '',
    password: '',
  }),
  withState('formMessage', 'setFormMessage', null),
  withState('showReactivateBtn', 'setShowReactivateBtn', false),
  withState('reactivateBtnLoading', 'setReactivateBtnLoading', false),
  withHandlers({
    handleEmailChange,
    handlePasswordChange,
    handleLogin,
    handleReactivate,
  }),
  withComponentDidMount(handleComponentDidMount),
  withComponentWillUnmount(handleComponentWillUnmount),
)(LoginView)
