import React from 'react'
import PropTypes from 'prop-types'
import InlineSvg from 'react-inlinesvg'
import sprite from 'assets/sprite.svg'
import 'assets/sprite.css'

export const SvgSprite = props => <InlineSvg src={sprite} uniquifyIDs={false} onLoad={props.onLoad} cacheGetRequests />

SvgSprite.propTypes = {
  onLoad: PropTypes.func,
}

SvgSprite.defaultProps = {
  onLoad: () => {},
}
