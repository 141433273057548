import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Input, Button } from 'components/ui-kit'
import { FormRow } from 'components/formRow/FormRow'
import { FORM_MESSAGE_TYPES, FormMessage } from 'components/formMessage/FormMessage'

import styles from './ResetPasswordView.module.scss'

export const ResetPasswordView = ({
  password,
  passwordError,
  handlePasswordChange,
  confirmPassword,
  confirmPasswordError,
  handleConfirmPasswordChange,
  isReady,
  formMessage,
  handleSubmit,
}) => {
  const isErrorMessage = formMessage && formMessage.type === FORM_MESSAGE_TYPES.ERROR

  return (
    <div>
      <FormRow>Enter new password for your account.</FormRow>
      <FormRow>
        <Input
          type="password"
          icon="user"
          className="transparent"
          placeholder="New password"
          value={password}
          error={passwordError}
          onChange={handlePasswordChange}
        />
      </FormRow>
      <FormRow>
        <Input
          type="password"
          icon="user"
          className="transparent"
          placeholder="Confirm password"
          value={confirmPassword}
          error={confirmPasswordError}
          onChange={handleConfirmPasswordChange}
        />
      </FormRow>
      <FormRow className={styles.backToLogin}>
        <Link to="/">Back to Log in</Link>
      </FormRow>
      <FormRow buttonContainer>
        <Button type="primary" size="big" disabled={!isReady} onClick={handleSubmit}>
          Send
        </Button>
      </FormRow>
      {formMessage && <FormMessage text={formMessage.text} type={formMessage.type} />}

      {isErrorMessage && (
        <div className={styles.backToForgotPassword}>
          <Link to="/password">
            <strong>Click Here</strong> to get a new reset password link
          </Link>
        </div>
      )}
    </div>
  )
}

ResetPasswordView.propTypes = {
  password: PropTypes.string.isRequired,
  passwordError: PropTypes.string.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  confirmPasswordError: PropTypes.string.isRequired,
  handleConfirmPasswordChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isReady: PropTypes.bool.isRequired,
  formMessage: PropTypes.object,
}
