import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import './Label.css'

export const Label = ({ isRequired = false, children }) => {
  if (isRequired) {
    return (
      <Fragment>
        {children}
        <span className="required-asterisk">*</span>
      </Fragment>
    )
  }

  return children
}

Label.propTypes = {
  isRequired: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string, PropTypes.object]),
}
