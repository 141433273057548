import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './SvgIcon.css'

export const SvgIcon = ({ withOpacity = false, name, width, height, className, onClick }) => {
  const attributes = {
    style: {
      width: width && parseInt(width, 10),
      height: height && parseInt(height, 10),
    },
  }

  return (
    <svg
      id={`icon-${name}`}
      className={classNames(`icons-${name} icons-${name}-dims ${className}`, { 'with-opacity': withOpacity })}
      onClick={onClick}
      {...attributes}>
      <use xlinkHref={`#icons-${name}`} {...attributes} />
    </svg>
  )
}

SvgIcon.propTypes = {
  withOpacity: PropTypes.bool,
  name: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onClick: PropTypes.func,
}

SvgIcon.defaultProps = {
  className: '',
}
