import queryString from 'query-string'
import { get } from 'lodash'
import axios from 'axios'

import {
  encodeBody,
  getFieldFromStorage,
  setFieldInStorage,
  isTokenExpired,
  decodeToken,
  removeFieldFromStorage,
  removeURLParameter,
  parseRedirectUrl,
} from 'utils/auth/helpers'
import { TOKENS_TYPES, INITIALISATION_FLOWS } from 'utils/auth/constants'
import { config } from 'lib/config'

export const tokenClient = axios.create({
  baseURL: `https://keycloak.${config.API_BASE_URL}/auth/realms/orion/protocol/openid-connect`,
  responseType: 'json',
  timeout: 10000,
  headers: {
    'Content-type': 'application/x-www-form-urlencoded',
  },
})

export const getRenewAccessTokenConfig = refreshToken => ({
  url: '/token',
  method: 'post',
  withCredentials: true,
  data: encodeBody({
    grant_type: 'refresh_token',
    client_id: 'ui-hris',
    refresh_token: refreshToken,
  }),
})

const getRedirectURIWithParams = params =>
  Object.keys(params).reduce((acc, key) => `${acc}${key}=${params[key]}&`, `${params.redirectUrl}#`)

export const getRequestTokenConfig = ({ email, password }) => ({
  url: '/token',
  method: 'post',
  data: encodeBody({
    username: email,
    password: password,
    grant_type: 'password',
    client_id: 'ui-hris',
  }),
})

class AuthManager {
  constructor() {
    this.redirectUrl = parseRedirectUrl(window.location.href)

    this.authenticated = false
    this.token = null
    this.tokenParsed = null
    this.refreshToken = null
    this.refreshTokenParsed = null
  }

  init() {
    return new Promise(resolve => {
      switch (this._getInitialisationFlow()) {
        case INITIALISATION_FLOWS.LOGOUT:
          this._processLogout()
          resolve(this.authenticated)
          break
        case INITIALISATION_FLOWS.RESET_PASSWORD:
          this._processResetPassword()
          resolve(this.authenticated)
          break
        case INITIALISATION_FLOWS.SET_TOKENS:
          this._processSetTokensFromUrl()
          resolve(this.authenticated)
          break
        case INITIALISATION_FLOWS.CHECK_IS_LOGGED_IN:
          this._handleCheckLogin()
          break
        default:
          this._loadTokenFromStorage()
            .then(() => {
              window.location.href = this._getRedirectUrl()
              resolve(this.authenticated)
            })
            .catch(() => resolve(this.authenticated))
      }
    })
  }

  login({ email, password }) {
    return new Promise((resolve, reject) => {
      tokenClient
        .request(getRequestTokenConfig({ email, password }))
        .then(({ data }) => {
          this._setToken({ accessToken: data.access_token, refreshToken: data.refresh_token })
          this.authenticated = true
          window.location.href = this._getRedirectUrl()
        })
        .catch(err => reject(err))
    })
  }

  updateToken() {
    return new Promise((resolve, reject) => {
      tokenClient
        .request(getRenewAccessTokenConfig(this.refreshToken))
        .then(({ data }) => {
          this._setToken({ accessToken: data.access_token, refreshToken: data.refresh_token })
          resolve(true)
        })
        .catch(err => reject(err))
    })
  }

  _getInitialisationFlow() {
    if (this._isResetPasswordFlow()) {
      return INITIALISATION_FLOWS.RESET_PASSWORD
    }

    if (this._isLogoutFlow()) {
      return INITIALISATION_FLOWS.LOGOUT
    }

    if (this._isSetTokensFlow()) {
      return INITIALISATION_FLOWS.SET_TOKENS
    }

    if (this._isCheckIsLoggedInFlow()) {
      return INITIALISATION_FLOWS.CHECK_IS_LOGGED_IN
    }

    return INITIALISATION_FLOWS.LOGIN
  }

  _getRedirectUrl() {
    return getRedirectURIWithParams({
      accessToken: this.token,
      refreshToken: this.refreshToken,
      newUrl: this.redirectUrl || config.API_BASE_URL,
      redirectUrl: this.redirectUrl || `${window.location.protocol}//${config.API_BASE_URL}`,
    })
  }

  _processResetPassword() {}

  _processSetTokensFromUrl() {
    const { access_token, refresh_token } = queryString.parse(window.location.hash)

    this._setToken({ accessToken: access_token, refreshToken: refresh_token })
    this.authenticated = true
    window.history.replaceState({}, null, window.location.origin + window.location.pathname)
    window.location.href = this._getRedirectUrl()
  }

  _handleCheckLogin() {
    return new Promise(() => {
      this._loadTokenFromStorage()
        .then(() => {
          window.location.href = getRedirectURIWithParams({
            token: this.token,
            refreshToken: this.refreshToken,
            redirectUrl: this.redirectUrl,
          })
        })
        .catch(() => {
          window.location.href = getRedirectURIWithParams({
            token: undefined,
            refreshToken: undefined,
            redirectUrl: this.redirectUrl,
          })
        })
    })
  }

  _isCheckIsLoggedInFlow() {
    return window.location.pathname === '/check-is-logged-in'
  }

  _isResetPasswordFlow() {
    return window.location.pathname === '/reset-password'
  }

  _processLogout() {
    removeFieldFromStorage(TOKENS_TYPES.REFRESH_TOKEN)
    removeFieldFromStorage(TOKENS_TYPES.ACCESS_TOKEN)
    window.history.replaceState({}, null, removeURLParameter({ url: window.location.href, parameter: 'logout' }))
  }

  _isLogoutFlow() {
    const requestParams = queryString.parse(window.location.search)

    return requestParams.logout
  }

  _isSetTokensFlow() {
    return window.location.pathname === '/set-tokens'
  }

  _loadTokenFromStorage() {
    return new Promise((resolve, reject) => {
      const tokenFromStorage = getFieldFromStorage(TOKENS_TYPES.ACCESS_TOKEN)
      const refreshTokenFromStorage = getFieldFromStorage(TOKENS_TYPES.REFRESH_TOKEN)

      if (tokenFromStorage && refreshTokenFromStorage) {
        this.tokenParsed = decodeToken(tokenFromStorage)
        this.refreshTokenParsed = decodeToken(refreshTokenFromStorage)
        this._setToken({ accessToken: tokenFromStorage, refreshToken: refreshTokenFromStorage })

        if (!isTokenExpired(get(this.tokenParsed, 'exp'))) {
          this.authenticated = true
          resolve(true)
        } else if (!isTokenExpired(get(this.refreshTokenParsed, 'exp'))) {
          this.updateToken()
            .then(authorised => resolve(authorised))
            .catch(err => reject(err))
        } else {
          reject(`Can't update access token.`)
        }
      } else {
        reject(`Can't receive token from storage.`)
      }
    })
  }

  _setToken({ accessToken, refreshToken }) {
    if (accessToken) {
      this.token = accessToken
      this.tokenParsed = decodeToken(accessToken)
      setFieldInStorage({ name: TOKENS_TYPES.ACCESS_TOKEN, value: accessToken })
    }

    if (refreshToken) {
      this.refreshToken = refreshToken
      this.refreshTokenParsed = decodeToken(refreshToken)
      setFieldInStorage({ name: TOKENS_TYPES.REFRESH_TOKEN, value: refreshToken })
    }
  }
}

export const AuthManagerInstance = new AuthManager()
