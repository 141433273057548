/**
 * Utility method which exports given value to window.ORION[name] = value for debugging purpose
 *
 * @param name
 * @param value
 */
export const exposeToWindow = (name, value) => {
  window.ORION = {
    ...(window.ORION || {}),
    [name]: value,
  }
}
