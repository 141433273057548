import { compose, withState, withHandlers } from 'recompose'
import { get } from 'lodash'
import { withRouter } from 'react-router-dom'

import { isEmailValid } from 'utils/helpers/email'
import { API } from 'utils/api/api'
import { isEnterPressed } from 'utils/helpers/keyboardHelpers'
import { encodeBody } from 'utils/auth/helpers'
import { withComponentDidMount } from 'hoc/withComponentDidMount'
import { withComponentWillUnmount } from 'hoc/withComponentWillUnmount'
import { FORM_MESSAGE_TYPES } from 'components/formMessage/FormMessage'
import { ForgotPasswordView } from 'containers/forgotPassword/ForgotPasswordView'

const handleEmailChange = ({ setEmail, setEmailError, setIsReady, emailError }) => newEmail => {
  setEmail(newEmail)

  if (!isEmailValid(newEmail)) {
    setEmailError('Email is not valid.')
    setIsReady(false)
  } else if (emailError) {
    setEmailError('')
    setIsReady(true)
  } else {
    setIsReady(true)
  }
}

let enterKeyPressEventListener
const handleComponentDidMount = ({ handleEnterKeyDown }) => {
  enterKeyPressEventListener = handleEnterKeyDown
  document.addEventListener('keypress', enterKeyPressEventListener)
}

const handleComponentWillUnmount = () => document.removeEventListener('keypress', enterKeyPressEventListener)

const handleEnterKeyDown = ({ isReady, handleSubmit }) => e => isEnterPressed(e) && isReady && handleSubmit()

const handleSubmit = ({ email, setIsPending, history, setFormMessage }) => () => {
  setIsPending(true)

  API.post(
    '/auth/password/reset',
    encodeBody({
      email,
    }),
  )
    .then(() => {
      history.push({
        pathname: '/',
        state: {
          formMessage: {
            type: FORM_MESSAGE_TYPES.SUCCESS,
            text: 'You should receive an email shortly with further instructions.',
          },
        },
      })
    })
    .catch(err => {
      const errors = get(err, 'response.data.errors')
      const errorToShow = errors[0] && get(errors[0], 'defaultMessage')

      errorToShow &&
        setFormMessage({
          type: FORM_MESSAGE_TYPES.ERROR,
          text: errorToShow,
        })
    })
    .finally(() => setIsPending(false))
}

export const ForgotPasswordContainer = compose(
  withRouter,
  withState('email', 'setEmail', ''),
  withState('emailError', 'setEmailError', ''),
  withState('formMessage', 'setFormMessage', null),
  withState('isReady', 'setIsReady', false),
  withState('isPending', 'setIsPending', false),
  withHandlers({
    handleEmailChange,
    handleSubmit,
  }),
  // Do not move this inside prev withHandlers call. as we run handleSubmit inside handleEnterKeyDown
  withHandlers({
    handleEnterKeyDown,
  }),
  withComponentDidMount(handleComponentDidMount),
  withComponentWillUnmount(handleComponentWillUnmount),
)(ForgotPasswordView)
