import { createContext } from 'react'
import moment from 'moment'

export const THEME_OPTIONS = {
  CHRISTMAS: 'christmas',
  REGULAR: 'regular',
}

// Christmas Theme Range is from 18 Dec. to 7 Jan. each year
const isChristmasTheme = () => {
  const themeStart = moment({ date: 18, month: 11 })
  const themeEnd = moment({ date: 8, month: 0 })

  return !moment().isBetween(themeEnd, themeStart)
}

export const getActiveTheme = () => {
  if (isChristmasTheme()) {
    return THEME_OPTIONS.CHRISTMAS
  }

  return THEME_OPTIONS.REGULAR
}

export const ThemeContext = createContext('theme')
