import { TOKEN_MIN_VALID_TIME } from './constants'

export const setFieldInStorage = ({ name, value }) => window.localStorage.setItem(name, value)

export const getFieldFromStorage = name => window.localStorage.getItem(name)

export const removeFieldFromStorage = name => window.localStorage.removeItem(name)

export const encodeBody = data => {
  const body = []

  for (const param in data) {
    if (data.hasOwnProperty(param)) {
      const encodedKey = encodeURIComponent(param)
      const encodedValue = encodeURIComponent(data[param])

      body.push(encodedKey + '=' + encodedValue)
    }
  }

  return body.join('&')
}

export const isTokenExpired = exp => {
  let expiresIn = exp - Math.ceil(new Date().getTime() / 1000)

  expiresIn -= TOKEN_MIN_VALID_TIME

  return expiresIn < 0
}

export const decodeToken = str => {
  str = str.split('.')[1]

  str = str.replace('/-/g', '+')
  str = str.replace('/_/g', '/')
  switch (str.length % 4) {
    case 0:
      break
    case 2:
      str += '=='
      break
    case 3:
      str += '='
      break
    default:
      // eslint-disable-next-line
      throw 'Invalid token'
  }

  str = (str + '===').slice(0, str.length + str.length % 4)
  str = str.replace(/-/g, '+').replace(/_/g, '/')

  str = decodeURIComponent(escape(window.atob(str)))

  str = JSON.parse(str)

  return str
}

export const removeURLParameter = ({ url, parameter }) => {
  //prefer to use l.search if you have a location/link object
  let urlparts = url.split('?')

  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + '='
    const pars = urlparts[1].split(/[&;]/g)

    //reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1)
      }
    }

    url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '')

    return url
  } else {
    return url
  }
}

export const parseRedirectUrl = url => url.split('redirect_uri=')[1]
