import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './Text.css'

const getElementClassNames = (className, type) => classNames(type, className)

export const Text = ({ children, className = '', type = 'body-3' }) => {
  switch (type) {
    case 'heading-1':
      return <h1 className={getElementClassNames(className, type)}>{children}</h1>
    case 'heading-2':
    case 'heading-4':
      return <h2 className={getElementClassNames(className, type)}>{children}</h2>
    case 'heading-3':
      return <h3 className={getElementClassNames(className, type)}>{children}</h3>
    case 'sub-head-1':
    case 'sub-head-2':
    case 'sub-head-3':
    case 'sub-head-4':
      return <h4 className={getElementClassNames(className, type)}>{children}</h4>
    case 'body-1':
    case 'body-2':
    case 'body-3':
    case 'body-4':
    case 'body-5':
    case 'body-6':
    case 'section-name':
    case 'column-name':
    case 'hint':
      return <span className={getElementClassNames(className, type)}>{children}</span>
    case 'secondary-line':
      return <span className={getElementClassNames(className, type)}>{children}</span>
    case 'paragraph':
      return <p className={getElementClassNames(className, type)}>{children}</p>
    default:
      return null
  }
}

Text.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'heading-1',
    'heading-2',
    'heading-3',
    'heading-4',
    'sub-head-1',
    'sub-head-2',
    'sub-head-3',
    'sub-head-4',
    'body-1',
    'body-2',
    'body-3',
    'body-4',
    'body-5',
    'body-6',
    'section-name',
    'column-name',
    'paragraph',
    'hint',
  ]),
}
