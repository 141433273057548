import React from 'react'

import posterImage from 'assets/images/video-background.jpg'

import styles from './VideoBackground.module.scss'

export const VideoBackground = () => (
  <div className={styles.videoBackgroundContainer}>
    <video loop="loop" preload="auto" muted playsInline poster={posterImage} />
    <div className={styles.videoBackgroundOverlay} />
  </div>
)
