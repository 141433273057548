import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { THEME_OPTIONS, ThemeContext } from 'configs/theme.config'
import { SvgIcon } from 'components/icon'

import styles from './Spinner.module.scss'

export const SPINNER_TYPES_OPTIONS = ['blue', 'white', 'gray', 'black']

export class Spinner extends Component {
  static propTypes = {
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.oneOf(SPINNER_TYPES_OPTIONS),
  }

  static contextType = ThemeContext

  get isChristmasTheme() {
    return this.context === THEME_OPTIONS.CHRISTMAS
  }

  get spinnerImg() {
    const { color = 'blue' } = this.props
    const isChristmasTheme = this.isChristmasTheme

    if (isChristmasTheme) {
      if (color === 'white') {
        return 'loader-christmas-white'
      }

      if (color === 'gray') {
        return 'loader-christmas-gray'
      }

      return 'loader-christmas'
    }

    return 'loader'
  }

  render() {
    const { className, width, height, color } = this.props
    const classList = classNames(styles.spinner, className, {
      [styles.christmasTheme]: this.isChristmasTheme,
      [styles.themeBlue]: color === 'blue',
      [styles.themeWhite]: color === 'white',
      [styles.themeBlack]: color === 'black',
      [styles.themeGray]: color === 'gray',
    })

    return <SvgIcon name={this.spinnerImg} width={width} height={height} className={classList} />
  }
}
