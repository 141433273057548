import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Input, Button } from 'components/ui-kit'
import { FormRow } from 'components/formRow/FormRow'
import { FormMessage } from 'components/formMessage/FormMessage'

import styles from './ForgotPasswordView.module.scss'

export const ForgotPasswordView = ({
  email,
  handleEmailChange,
  handleSubmit,
  emailError,
  isReady,
  isPending,
  formMessage,
}) => (
  <div>
    <FormRow>Enter your email address and we’ll send you a link to set/reset your password.</FormRow>
    <FormRow>
      <Input
        icon="user"
        className="transparent"
        placeholder="Email"
        value={email}
        error={emailError}
        onChange={handleEmailChange}
      />
    </FormRow>
    <FormRow className={styles.backToLogin}>
      <Link to="/">Back to Log in</Link>
    </FormRow>
    <FormRow buttonContainer>
      <Button type="primary" size="big" disabled={!isReady} onClick={handleSubmit} pending={isPending}>
        Send
      </Button>
    </FormRow>
    {formMessage && <FormMessage text={formMessage.text} type={formMessage.type} />}
  </div>
)

ForgotPasswordView.propTypes = {
  isReset: PropTypes.bool,
  email: PropTypes.string.isRequired,
  emailError: PropTypes.string.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isReady: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  formMessage: PropTypes.object,
}
